*{
  box-sizing: border-box;
}

@font-face {
font-family: "Archia";
src: local("Archia"),
 url("./fonts/archia-regular-webfont.ttf") format("truetype");
}

html, body{
width: 100%;
height: 100%;
background-color: #1f1f1f !important;
color: #ECFAEC !important;
font-family: Archia, SF Pro Display, Roboto, Helvetica, Arial, sans-serif !important;
}

label{
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  font-family: Archia, SF Pro Display, Roboto, Helvetica, Arial, sans-serif !important;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  color: #FF923D!important;
}

#root{
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ECFAEC;
  -webkit-box-shadow: 0 0 0px 0px rgba(236, 250, 236, 0.1) inset;
  transition: background-color 5000s ease-in-out 0s;
}